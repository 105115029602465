import React from "react";
import "../../styles/header footer styles/footer.css";
import { GrLinkedin, GrTwitter, GrYoutube } from "react-icons/gr";
import logo from "../../assets/niftmint_rewards_logo.png";

const Footer = () => {
  return (
    <>
      <div className="parent">
        <div className="container">
          <div className="footerFirstRowAllignment">
            <div className="footer-logos">
              <img
                  src={logo}
                  alt="Niftmint Rewards Logo"
                  style={{ width: "auto", height: "50px", objectFit: "contain" }} // Adjust size as needed
              />
            </div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
              <div className="upper-section d-flex justify-content-end text-white pt-4 pt-lg-0">

                <div className="common-div">
                  <div className="footer-heading">Support</div>
                  <div><a className="footer-link" href="https://niftmint.com/">Contact Sales</a></div>
                  <div><a className="footer-link" href="mailto:info@niftmint.com">Customer Support</a></div>
                  <div><a className="footer-link" href="https://niftmint.com/faq">FAQ</a></div>
                </div>

                <div className="common-div">
                  <div className="footer-heading">Products</div>
                  <div><a className="footer-link" href="https://niftmint.com/">Authentication</a></div>
                  <div><a className="footer-link" href="https://niftmint.com/rewards">Rewards</a></div>
                </div>

                <div className="common-div">
                  <div className="footer-heading">Resources</div>
                  <div><a className="footer-link" href="https://niftmint.com/news">News</a></div>
                  <div><a className="footer-link" href="https://niftmint.com/terms-and-conditions">Terms and Conditions</a></div>
                  <div><a className="footer-link" href="https://niftmint.com/privacy-policy">Privacy Policy</a></div>
                </div>

              </div>
            </div>
              </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
