import React, {useContext, useEffect, useState} from "react";
import {LockedNFT, StandardNFTCard} from "../../components/Cards/NftCards";
import {
    API_BASE_URL,
    API_ROUTES,
} from "../../constants/ApiBaseUrl";
import {configOption} from "../../utils/HttpHeaders";
import Axios from "axios";
import "../../styles/user data/userdata.css";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TagsInput from "react-tagsinput";
import MintSuccess from "../../components/modals/MintSucess";
import {connect} from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import "react-tagsinput/react-tagsinput.css";
import Logo from "../../components/logo/logo";
import {UserContext} from "../../context/context";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon
                        style={{
                            backgroundColor: "#101010",
                            color: "white",
                            borderRadius: "25px",
                            padding: "4px 4px",
                        }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Home = (props) => {
    const {mintNFT} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [invitePopup, setInvitePopup] = useState(false);
    const [allNfts, setAllNfts] = useState([]);
    const [openInvite, setOpenInvite] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(null);
    const [tags, setTags] = useState([]);
    const [isAvailable, setIsAvailable] = useState(false);
    const [minEmailAddresses, setMinEmailAddresses] = useState(1);
    const [selectedNft, setSelectedNft] = useState({});
    const [minEmailAddressesError, setMinEmailAddressesError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    // const [email, setEmail] = useState("");
    const email = props.auth.user.email;
    const handleTagsChange = (tags) => {
        setTags(tags);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccess(false);
    };
    const handleCloseInvite = () => {
        setOpenInvite(false);
        setInvitePopup(false);
    };
    const handleOpenInvite = (nft) => {
        setOpenInvite(true);
        setInvitePopup(true);
        setMinEmailAddresses(nft.display_order);
        setSelectedNft(nft);
    };

    async function claimNft(nft) {
        try {
            setIsLoading(true);
            console.log(`Claim NFT ${nft.display_order}, ${nft.nft_id}`)

            const res = await Axios.post(
                `${API_BASE_URL}/nft_collection/${nft.nft_collection_id}/nft`,
                configOption()
            );
            console.log(res);

            if (res.status === 201) {
                toast.success("Minting Completed");
            } else {
                toast.error("Minting Error please try again");
            }
            setIsLoading(false);
            window.location.reload();
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getNftCollections = async () => {
        if (isLoading === true)
            return;

        try {
            setLoadingPage(true);

            const res1 = await Axios.get(
                `${API_BASE_URL}/nft_collection`,
                configOption()
            );
            const collections = res1.data

            const res2 = await Axios.get(
                `${API_BASE_URL}/nft`,
                configOption()
            );
            const mints = res2.data

            //console.log(collections)
            //console.log(mints)

            for (let collection of collections) {
                for (const mint of mints) {
                    if (mint.nft_collection_id === collection.nft_collection_id) {
                        collection.minted = true
                        collection.nft_id = mint.nft_mint_id;
                        collection.transaction_hash = mint.transaction_hash;
                        collection.token_id = mint.token_id;
                        //collection.minting = mint.token_id === null
                    }
                }
            }
            setAllNfts(collections);
            console.log(collections)

            // setIsAvailable(true);
            setLoadingPage(false);
        } catch (error) {
            // setIsAvailable(false);
        }
    };

    useEffect(() => {
        getNftCollections();
    }, [props.auth.user, isLoading]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return (
        <div className="container">
            <div className=" ">
                <Spinner loading={loadingPage}/>
            </div>
            <div className={` ${loadingPage === true ? "notready" : "ready"}`}>
                <div className="row nft-card-row">

                    {allNfts.map((nft, index) => (
                        <div key={index} className="">
                            <StandardNFTCard
                                nft={allNfts[index]}
                                navigate={navigate}
                                index="1"
                                claimNft={claimNft}
                                settingFunction={() => setSelectedIdx(index)}
                                {...props}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Home.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.error.error,
    auth: state.auth,
});

export default connect(mapStateToProps)(Home);
