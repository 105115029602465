import React from "react";
import {CircularProgress} from "@mui/material";

export const StandardNFTCard = ({
                                    nft,
                                    navigate,
                                    index,
                                    claimNft,
                                    settingFunction,
                                }) => {
    return (
        <div className="nft-card-parent my-3">
            <div className="blur-container">
                <img alt="" src={nft.common_image_url} className="buy-nft-img blur-img"/>
            </div>
            <div className="collection-box p-3">
                <h4 className="font-weight-bold buy-title">{nft.name}</h4>

                <div className="buy-now-footer pt-3">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            {nft.minted && (!nft.minting) ? (
                                <button
                                    className="header-btn text-dark font-weight-bold"
                                    style={{background: "#E0D2A1"}}
                                    onClick={(e) =>
                                        navigate("/view-nft", {
                                            state: {
                                                id: nft.nft_id,
                                            },
                                        })
                                    }
                                >
                                    View
                                </button>
                            ) : (
                                <button
                                    disabled={nft.minting}
                                    className="header-btn text-dark font-weight-bold"
                                    style={{background: "#E0D2A1"}}
                                    onClick={() => {
                                        claimNft(nft);
                                        settingFunction();
                                    }}
                                >
                                    <span>Claim</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LockedNFT = ({
                              nft,
                              navigate,
                              index,
                              referrals,
                              openInviteModal,
                              isLoading,
                              isSelected,
                              settingFunction,
                              isAvailable,
                              claimNft,
                              claimLoading,
                          }) => {
    const inviteFunctions = () => {
        settingFunction(index);
        openInviteModal(nft);
        settingFunction(null);
    };

    const lockedFunctions = async () => {
        settingFunction(index);
        await claimNft(nft);
    };

    const handleSwitchFunctions = () => {
        const funcToCall = index === 1 ? inviteFunctions : lockedFunctions;

        funcToCall();
    };
    return (
        <div className="nft-card-parent my-3">
            <div className="blur-container">
                <img alt="" src={nft.image_url} className="buy-nft-img blured-nft"/>
            </div>
            <div className="collection-box  p-3 ">
                <h4 className="font-weight-bold buy-title unlockText">
                    {/* You need to send {referrals} more invites to unlock this NFT. */}
                    {index === 1
                        ? `Send ${referrals + 1} invites to unlock this NFT`
                        : index === 2
                            ? `Unlocks after ${referrals} invites claimed`
                            : null}
                </h4>

                {isSelected === 1 ? <></> : <></>}
                <div className="buy-now-footer pt-3">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <button
                                disabled={index === 2 ? !isAvailable : isLoading}
                                className={`header-btn ${
                                    index === 2 ? "text-white" : "text-dark"
                                }  font-weight-bold`}
                                style={{background: index === 1 ? `#E0D2A1` : `#000`}}
                                onClick={() => {
                                    handleSwitchFunctions();
                                }}
                            >
                                {index === 1 ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginInline: "5px",
                                        }}
                                    >
                                        {isLoading && isSelected === 1 ? (
                                            <span>
                        Minting <CircularProgress color="inherit" size={12}/>
                      </span>
                                        ) : (
                                            <span>Invite</span>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginInline: "5px",
                                        }}
                                    >
                                        <>
                                            {isLoading && isSelected === 2 ? (
                                                <span>
                          Minting <CircularProgress color="inherit" size={12}/>
                        </span>
                                            ) : index === 2 && !isAvailable ? (
                                                <span>Locked</span>
                                            ) : (
                                                <>
                                                    <span>Claim</span>
                                                </>
                                            )}
                                        </>
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
