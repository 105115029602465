import React, {useEffect, useState} from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import MetaMaskWallet from "../../pages/user data/collection/MetaMaskWallet";
import UserPopup from "./UserPopup";
import Logo from "../logo/logo";
import Axios from "axios";
import {API_BASE_URL} from "../../constants/ApiBaseUrl";
import {configOption} from "../../utils/HttpHeaders";

const HomeHeader = () => {
    const navigate = useNavigate();
    const [tokenBalance, setTokenBalance] = useState(0);

    const getTokenRewardBalance = async () => {
        try {
            const res = await Axios.get(
                `${API_BASE_URL}/token_reward`,
                configOption()
            );
            setTokenBalance(res.data.nmt_token_balance)
        } catch (error) {
        }
    };

    useEffect(() => {
        getTokenRewardBalance();
    }, [tokenBalance]);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light container">
                <Link to="/">
                    <Logo/>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarText"
                    aria-controls="navbarText"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav mr-auto"></ul>
                    <div className="token-balance">Niftmint Rewards Balance: <b>{tokenBalance}</b></div>
                    <UserPopup/>{" "}
                </div>
            </nav>
            {/* <div className="container">
        <div className="row pt-2 algin-content-center">
          <div className="xs-4 col-4 col-sm-4 col-md-4 col-lg-4 d-flex">
            <div className="logo d-flex align-items-center">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
          <div className="xs-8 col-8 col-sm-8 col-md-8 col-lg-8">
            <div className="text-right">
              <span>
              <UserPopup />
              </span>
            </div>
          </div>
        </div>
      </div> */}
        </>
    );
};

export default HomeHeader;
