import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Axios from "axios";
import {
  API_ROUTES,
  API_BASE_URL,
} from "../../../constants/ApiBaseUrl";
// import CompleteWalletDialog from "../../../components/dialog modal/CompleteWalletDialog";
// import HomeTransferDialog from "../../../components/dialog modal/HomeTransferDialog";
import Spinner from "../../../components/spinner/Spinner";
import { toast } from "react-toastify";
import { configOption } from "../../../utils/HttpHeaders";
import {
  convertDate,
  TimeStampToDate,
} from "../../../utils/Mongotimeconverter";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
// import TransferFormDialog from "./TransferFormDialog";
import { Navigate, useNavigate } from "react-router-dom";
import "../../../styles/home style/Home.css";
import MetaMaskWallet from "./MetaMaskWallet";
// import TransferSuccessfullDialog from "../../../components/dialog modal/TransferSucessfullDialog";
// import Radio from "@mui/material/Radio";
import InviteModal from "../../../components/dialog modal/InviteModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import icon from "../../../metmaskimg.png";
import { CircularProgress } from "@mui/material";
import { UserContext } from "../../../context/context";
import { connect } from "react-redux";

import Logo from "../../../components/logo/logo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            style={{
              backgroundColor: "#101010",
              color: "white",
              borderRadius: "25px",
              padding: "4px 4px",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//==================================================================================================================================================================================================================

const ViewNft = (props) => {
  //const [address, setAddress] = useState("");

  const [transferSuccessfull, setTransferSuccessfull] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);

  const [email, setEmail] = useState("email");
  const [first_name, setFirst_name] = useState("firstName");
  const [last_name, setLast_name] = useState("lastName");
  const [token_id, setToken_id] = useState("tokenID");
  const [wallet_address, setWallet_address] = useState();
  const [confirm_wallet_address, setConfirm_Wallet_address] =
    useState("confrm");
  const [other_wallet_address, setOther_Wallet_address] = useState("");
  const [smartContactAddress, setSmartContractAddress] = useState("");

  const { transferNFT } = useContext(UserContext);

  // var first_nameRef = useRef(null);
  // var last_nameRef = useRef(null);
  // var wallet_addressRef = useRef(null);
  // var confirm_wallet_addressRef = useRef(null);
  // var other_wallet_addressRef = useRef(null);
  // var emailRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [op, setOp] = React.useState(false);
  // const navigate = useNavigate();
  const [transferFormPopup, setTransferFormPopup] = useState(false);

  const location = useLocation();
  //  const [id, setId] = useState("");
  const [getNft, setGetNft] = useState("");
  const [loading, setLoading] = useState(false);

  const [checkTransfer, setCheckTransfer] = useState("");

  const [nftId, setNftId] = useState("");

  const [transferPopup, setTransferPopup] = useState(false);

  const [completeWallet, setCompleteWallet] = useState(false);

  const [selectedValue, setSelectedValue] = useState("metawallet");
  const [newPopup, setNewPopup] = useState(false);
  const [inviteModalStatus, setInviteModalStatus] = useState(false);

  //=======================================================================================================================
  function handleBlockchain() {
    var add = sessionStorage.getItem("wallet address");
    setWallet_address(add);
    // wallet_address = add;
    // setFlag(!flag);
    // window.location.href = "/user-info";
  }

  //=======================================================================================================================

  const handleChange = (event) => {
    if (event.target.value === "otherwallet") {
      setWallet_address("");
    }
    // confirm_wallet_address = null;
    // other_wallet_address = null;
    setSelectedValue(event.target.value);
  };

  const validateForm = (e) => {
    // debugger;
    e.preventDefault();
    // debugger;
    if (!email || !first_name || !last_name) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (selectedValue === "metawallet" && !wallet_address) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (
      selectedValue === "otherwallet" &&
      (!other_wallet_address || !confirm_wallet_address)
    ) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (
      selectedValue === "otherwallet" &&
      other_wallet_address !== confirm_wallet_address
    ) {
      toast.error("Wallet addresses do not match.");
      return false;
    } else {
      handleSubmit();
    }
  };

  //=======================================================================================================================
  const setInviteModalFalse = () => {
    setInviteModalStatus(false);
  };

  const handleSubmit = async () => {
    var txResponse;
    try {
      setIsTransferring(true);

      if (selectedValue === "metawallet") {
        /*
        txResponse = await transferNFT(
          smartContactAddress,
          BRAND_WALLET,
          wallet_address,
          token_id
        );

        console.log(txResponse);
         */
      } else if (selectedValue === "otherwallet") {
        /*
        txResponse = await transferNFT(
          smartContactAddress,
          BRAND_WALLET,
          other_wallet_address,
          token_id
        );
        console.log("txResponse", txResponse);
         */
      }
      if (txResponse.success === true) {
        await Axios.get(
          `${API_BASE_URL}${API_ROUTES.NFT.TRANSFER_CONFIRMED}/${getNft.id}`,
          configOption()
        );
        toast.success("NFT Transfer Completed");
        setTransferSuccessfull(true);
        setWallet_address("");
        setIsTransferring(false);
        setConfirm_Wallet_address("");
        setOpen(false);
      } else {
        toast.error("Transfer Error");
        setIsTransferring(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTransferFormPopup(false);
  };

  //=======================================================================================================================

  const checkWallet = async () => {
    const { id } = JSON.parse(localStorage.niftMintUser);
    setTransferFormPopup(true);
    handleClickOpen();
    try {
      // const res = await Axios.post(
      //   `${API_BASE_URL}${API_ROUTES.NFT.NFT_TRANSFER_STATUS}`,
      //   {
      //     nft_id: location.state.id,
      //     user_id: id,
      //   },
      //   configOption()
      // );
      // if (res.status === 200) {
      // if (res.data.minted === true) {
      //   setTransferFormPopup(true);
      //    handleClickOpen();
      //   setOpen(true);
      // } else if (res.data.minted === undefined) {
      //   setTransferFormPopup(true);
      //   // handleClickOpen();
      //   setOpen(true);
      // } else {
      //   setNewPopup(true);
      //   //    setCompleteWallet(true);
      //   // handleClickOpen();
      //   setOpen(true);
      // }
      //}
    } catch (error) {}
  };

  //=======================================================================================================================

  const getFullDetails = async () => {
    // const { id } = JSON.parse(sessionStorage.getItem("niftMintUser"));
    setLoading(false);
    try {
      setLoading(true);

      console.log(location.state.id)
      const res = await Axios.get(
        `${API_BASE_URL}${API_ROUTES.NFT.NFT}/${location.state.id}`,
        configOption()
      );
      setGetNft(res.data);

      console.log(getNft);
      /* TODO by Colin ??
      setSmartContractAddress(
        res.data.contractAddress.replace(
          "CID:NIFT721nBrandNFTv2022r0:137:",
          ""
        )
      );
      setToken_id(
        res.data.collectible?.collectibleData?.ref
          .match(/:\d+/g)
          .pop()
          .match(/\d+/)[0]
      );
      */
      setTimeout(function () {
        setLoading(false);
      }, 1000);
    } catch (error) {}
  };
  //=======================================================================================================================

  useEffect(() => {
    getFullDetails();
    setNftId(location.state?.id);
  }, [location.state?.id, isTransferring, props.auth.user?.id]);

  return (
    <>
      <div className="container home-container">
        <div className=" ">
          <Spinner loading={loading} />
        </div>
        <div className={` ${loading === true ? "notready" : "ready"}`}>
          <div className="row  mt-3 mb-3 common-row">
            {" "}
            <div className="col-12 col-md-5 col-lg-6 video-section">
              <span className="">
                {" "}
                {/* <Link to="/user-info"> */}
                <img
                  onClick={() => window.history.back()}
                  src="/assets/images/Arrow.png"
                  alt=""
                  className=""
                />
                {/* </Link> */}
              </span>

              <div className="mt-3">
                <img
                  src={getNft.image_url}
                  className="w-100 "
                  style={{
                    borderRadius: "20px",
                    // height: "400px",
                    // objectFit: "cover",
                  }}
                  alt=""

                  // height={600}
                />
              </div>

              <div className="afternftBox">
                <h4 className="font-weight-bold">
                  {/* Your Christology Statement Digital Collectible{" "} */}
                  {getNft.name}
                </h4>
                <h6 className="font-weight-bold">Description</h6>
                This Digital Collectible will be permanently associated with this email address
                until you transfer to your own digital wallet.
                {/* {isReadMore
                  ? getNft.description?.slice(0, 150)
                  : getNft.description} */}
                {/* <span onClick={toggleReadMore} className="read-or-hide">
                  {isReadMore ? (
                    <span className="afternftBox-readMore"> ...read more</span>
                  ) : (
                    <span className="afternftBox-readMore"> show less</span>
                  )}
                </span> */}
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-6 home-section-second">
              <div className="header">
                <div>
                  {/* Invite dialog box end */}
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <BootstrapDialogTitle
                      id="customized-dialog-title"
                      onClose={handleClose}
                    >
                      <Logo />
                    </BootstrapDialogTitle>

                    {newPopup === true ? (
                      <>
                        {" "}
                        <DialogContent dividers>
                          <h3 className="transfer-heading">Transfer</h3>
                          <Typography className="para pt-2">
                            This Digital Collectible is not ready to be
                            transferred at this time. Please allow 48 hours for
                            transfer to enabled.
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <button className="dialog-btn" onClick={handleClose}>
                            Close
                          </button>
                        </DialogActions>
                      </>
                    ) : transferFormPopup === true ? (
                      <>
                        <DialogContent dividers>
                          <h3 className="transfer-heading">Transfer</h3>
                          <Typography className="para pt-2">
                            To transfer your Digital Collectible, you must first
                            have a crypto wallet and provide your public wallet
                            address. Please make sure you provide the exact
                            wallet address as Christology Statement will not be
                            responsibe if you provide an incorrect address. You
                            will not have the ability to change the wallet
                            address once the Digital Collectible is sent.
                          </Typography>
                          <Typography gutterBottom className="pt-2 para">
                            Transfering your Digital Collectible means it is no
                            longer in Christology Statement’s custody and
                            custody will move to address provided.
                          </Typography>
                          <h5 className="transfer-head text-right pt-4">
                            Are you sure you want to transfer your Digital
                            Collectible?
                          </h5>
                        </DialogContent>
                        <DialogActions>
                          <button
                            className="dialog-btn"
                            onClick={() => {
                              setTransferFormPopup(false);
                              //  setCompleteWallet(true);
                              // setOpen(false);
                              // setTransferFormPopup(true);
                            }}
                          >
                            Transfer
                          </button>
                        </DialogActions>
                      </>
                    ) : (
                      <>
                        <DialogContent dividers>
                          <h3 className="transfer-heading">Transfer</h3>
                          <Typography gutterBottom style={{ fontSize: "14px" }}>
                            For this Digital Collectible your wallet must be
                            Polygon compatible and configured to receive Digital
                            Collectibles.
                          </Typography>

                          {isTransferring && (
                            <Typography
                              gutterBottom
                              style={{
                                fontSize: "14px",
                                color: "red",
                                marginBlock: "5px",
                              }}
                            >
                              Please do not refresh this page while transferring
                              you collectible.
                            </Typography>
                          )}

                          <div className="form mt-3">
                            <form onSubmit={validateForm}>
                              <div className="form-group">
                                <label className="forms-labelss">
                                  Wallet Address
                                </label>

                                <div>
                                  <FormControl>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name="controlled-radio-buttons-group"
                                      value={selectedValue}
                                      onChange={handleChange}
                                    >
                                      <div className="d-flex">
                                        <div className="pr-3">
                                          <FormControlLabel
                                            value="metawallet"
                                            control={
                                              <Radio
                                                style={{ color: "#383A3C" }}
                                              />
                                            }
                                            label={
                                              // eslint-disable-next-line jsx-a11y/alt-text
                                              <img
                                                src={icon}
                                                width={35}
                                                height={35}
                                                style={{
                                                  cursor: "pointer",
                                                  objectFit: "cover",
                                                  borderRadius: "25px",
                                                }}
                                              />
                                            }
                                          />
                                        </div>
                                        <div>
                                          <FormControlLabel
                                            value="otherwallet"
                                            control={
                                              <Radio
                                                style={{ color: "#383A3C" }}
                                              />
                                            }
                                            label="Other Wallet"
                                          />
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                {selectedValue === "metawallet" ? (
                                  <>
                                    {" "}
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="text"
                                        className="form-control mr-1"
                                        placeholder="Connect Metamask wallet address"
                                        id="wallet_add"
                                        value={wallet_address}
                                        readonly="readonly"
                                        // value={wallet_address}
                                      />

                                      <MetaMaskWallet
                                        handleBlockchain={handleBlockchain}
                                        // getAddress={getAddress}
                                        handleClose={handleClose}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex align-items-center pt-3">
                                      <input
                                        type="text"
                                        className="form-control "
                                        placeholder="add other wallet address"
                                        // disabled={selectedValue != "b"}
                                        value={other_wallet_address}
                                        onChange={(e) =>
                                          setOther_Wallet_address(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="form-group pt-3">
                                      {/* <label className="forms-labelss">
                                          Re-enter Wallet Address
                                        </label> */}
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Re-enter wallet address"
                                        value={confirm_wallet_address}
                                        // value={confirm_wallet_address}

                                        onChange={(e) =>
                                          setConfirm_Wallet_address(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="text-white text-right mb-3">
                                <button
                                  // disabled={isTransferring === true}
                                  disabled={wallet_address === ""}
                                  type="submit"
                                  className="dialog-btn "
                                >
                                  {isTransferring ? (
                                    <span>
                                      Transferring{" "}
                                      <CircularProgress
                                        color="inherit"
                                        size={12}
                                      />
                                    </span>
                                  ) : (
                                    "Transfer"
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </DialogContent>
                      </>
                    )}
                  </BootstrapDialog>
                </div>
              </div>
              <div className="form mt-4">
                <div
                  className="form-group"
                  style={{ borderBottom: "1px solid lightGray" }}
                >
                  <label htmlFor="pwd" className="forms-labelss">
                    Transaction
                  </label>
                  <div className="home-data" style={{ fontSize: "13px", fontWeight: "bold" }}>
                    <a href={"https://polygonscan.com/tx/" + getNft.transaction_hash} target="_blank" rel="noopener noreferrer">{getNft?.transaction_hash}</a>
                  </div>
                </div>
                {/*
                  <div
                    className="form-group"
                    style={{ borderBottom: "1px solid lightGray" }}
                  >
                    <label htmlFor="pwd" className="forms-labelss">
                      Contract Address
                    </label>
                    <div className="home-data pb-2">
                      <a
                        href={`https://polygonscan.com/address/${getNft.contract_address}`}
                        target="_blank "
                        className="anchr-link "
                        style={{ overflowWrap: "break-word", color: "#007bff" }}
                      >
                        {getNft.contract_address}
                      </a>
                    </div>
                  </div>
                */}

                <div
                  className="form-group"
                  style={{ borderBottom: "1px solid lightGray" }}
                >
                  <label htmlFor="pwd" className="forms-labelss">
                    Blockchain
                  </label>
                  <div className="home-data" style={{ fontWeight: "bold" }}> Polygon</div>
                </div>

                <div
                  className="form-group"
                  style={{ borderBottom: "1px solid lightGray" }}
                >
                  <label htmlFor="pwd" className="forms-labelss">
                    Standard
                  </label>
                  <div className="home-data" style={{ fontWeight: "bold" }}> ERC721</div>
                </div>

                <div
                  className="form-group"
                  style={{ borderBottom: "1px solid lightGray" }}
                >
                  <label htmlFor="pwd" className="forms-labelss">
                    Collectable ID
                  </label>
                  <div className="home-data pb-2" style={{ fontWeight: "bold" }}>
                    {getNft?.token_id}
                  </div>
                </div>
                {/*
                <div
                  className="form-group"
                  style={{ borderBottom: "1px solid lightGray" }}
                >
                  <label htmlFor="pwd" className="forms-labelss">
                    Current Custodian
                  </label>
                  <div className="home-data pb-2">
                    {getNft.currentCustodian}
                    {}
                  </div>
                </div>
                */}
                <div className="d-flex view-nft-second-div">
                  <div className="mr-2 chatbtnspace">
                    {/*
                    <p className="forms-labelss pt-3 ">
                      {getNft.transferred === true
                        ? " You transferred"
                        : "Transfer"}{" "}
                      this NFT
                    </p>
                    {!getNft.transferred ? (
                      <button
                        // className="header-btn"
                        className={` ${
                          getNft.transferred === true
                            ? "btn btn-primary register-btndisable"
                            : "transfer-chat-btn"
                        }`}
                        onClick={checkWallet}
                        disabled={isTransferring === true}
                      >
                        {isTransferring ? (
                          <span>
                            Transferring
                            <CircularProgress color="inherit" size={12} />
                          </span>
                        ) : (
                          "Transfer"
                        )}
                      </button>
                    ) : null}
                    <button
                      type="submit"
                      className="transfer-chat-btn"
                      style={{ marginLeft: "10px" }}
                      onClick={() => setInviteModalStatus(true)}
                    >
                      Invite
                    </button>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InviteModal
        openInvite={inviteModalStatus}
        handleCloseInvite={setInviteModalFalse}
        selectedNft={getNft}
        {...props}
      />
    </>
  );
};

ViewNft.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(ViewNft);
