import React from "react";
import Logo from "../logo/logo";

const LogoOnlyHeader = () => {
  return (
    <>
      <div className="container">
        <div className="row pt-3">
          <div className="xs-4 col-4 col-sm-4 col-md-4 col-lg-4 d-flex">
            <div className="logo d-flex align-items-center">
              <Logo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoOnlyHeader;
