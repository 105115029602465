import React, { useState, useEffect } from "react";
import "../../../styles/user data/userdata.css";
import CommonSection from "../common-section/CommonSection";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";
import { configOption } from "../../../utils/HttpHeaders";
import {
  API_BASE_URL,
  API_ROUTES,
} from "../../../constants/ApiBaseUrl";
import Spinner from "../../../components/spinner/Spinner";
import { toast } from "react-toastify";
import { StandardNFTCard, LockedNFT } from "../../../components/Cards/NftCards";

const Collection = (props) => {
  const navigate = useNavigate();
  const [claimLoading, setClaimLoading] = useState(
    Array(props.allNft.length).fill(false)
  );

  const UpdatingLoading = (status, index) => {
    setClaimLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = status;
      return newLoading;
    });
  };

  const claimNft = async (nft, index) => {
    try {
      UpdatingLoading(true, index);

      const res = await Axios.post(
        `${API_BASE_URL}${API_ROUTES.NFT.CLAIM_NFT}`,
        {
          id: nft.id,
          name: nft.name,
          description: nft.description,
          nftImg: nft.nftImg,
          eventId: nft.eventId,
        },
        configOption()
      );
      if (res.data.data.statusCode === 200) {
        toast.success(res.data.data.message);
        UpdatingLoading(false, index);
        // window.location.reload();
      }
    } catch (error) {
      UpdatingLoading(false, index);
      // toast.error(error.response.data.message);
    }
  };
  const { allNft, loading, nfts } = props;
  return (
    <>
      <div className="container">
        <div className=" ">
          <Spinner loading={loading} />
        </div>
        <div className={` ${loading === true ? "notready" : "ready"}`}>
          <div className="row">
            {allNft.length > 0 ? (
              allNft.map((nft, index) => {
                return (
                  <>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 ">
                      <StandardNFTCard
                        nft={nft}
                        index={index}
                        claimLoading={claimLoading}
                        claimNft={claimNft}
                        navigate={navigate}
                      />
                    </div>
                  </>
                );
              })
            ) : (
              <>
                {nfts <= 0 ? (
                  <div className="w-100">
                    <h5 style={{ color: "red" }}>
                      {props.claimedAllNfts ? (
                        <marquee>You have claimed all your nfts</marquee>
                      ) : (
                        <marquee>
                          You have haven’t claimed any of your nfts
                        </marquee>
                      )}
                    </h5>
                  </div>
                ) : (
                  <div className="w-100">
                    <h5 style={{ color: "red" }}>
                      <marquee>You haven’t attended any events yet</marquee>
                    </h5>
                  </div>
                )}

                <div className="col-12 col-sm-12 col-md-6 col-lg-5 mb-3">
                  <div className="my-3">
                    <img
                      src="/assets/images/membership.png"
                      alt=""
                      className="w-100"
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collection;
