import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import RegisterButtonHeader from "../../components/headers/RegisterButtonHeader";
import "../../styles/auth style/auth.css";
import {
    API_ROUTES,
    API_BASE_URL,
} from "../../constants/ApiBaseUrl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
    setUserLocally,
    setCurrentUser,
    setError,
    setToken,
} from "../../state/actions/auth";
import {toast} from "react-toastify";
import Axios from "axios";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const validateForm = (e) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error("Please fill all the fields.");
            return false;
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        try {
            const res = await Axios.post(
                    `${API_BASE_URL}${API_ROUTES.AUTH_USER.LOGIN}`,
                    {
                        email: email,
                        password: password,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
            setEmail("");
            setPassword("");
            if (res.status === 200) {
                toast.success("Welcome back.");
                const user = setUserLocally(res.data);
                setToken(user);
                props.dispatch(setCurrentUser(user));
                props.dispatch(setError({}));
                localStorage.setItem("niftMintToken", user.auth_token);

                window.location.href = "/";

                /* Don't care about this right now..
                if (res.data.first_name && res.data.last_name) {
                    window.location.href = "/";
                } else {
                    navigate("/complete-account");
                }
                 */
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <>
            <RegisterButtonHeader/>
            <div className="container login-container ">
                <div className="row  mt-3 mb-3 common-row ">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
                        <img src="/assets/images/membership.png" className="w-100 gif" alt=""/>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 login-section-second pt-5 ">
                        <div className="header">
                            <h2 className="title" >
                                Earn Niftmint Tokens, Cash Back, and Exclusive Offers!
                            </h2>
                            <p className="text">
                                Sign in with your Email and Password to view your Digital
                                Collectible
                            </p>
                        </div>
                        <div className="form">
                            <form onSubmit={validateForm}>
                                <div className="form-group">
                                    <label
                                        htmlFor="email"
                                        className="forms-labels"
                                        style={{color: "#B2BEB5"}}
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="your@email.com"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor="pwd"
                                        className="forms-labels"
                                        style={{color: "#B2BEB5"}}
                                    >
                                        Password
                                    </label>
                                    <input
                                        type="Password"
                                        className="form-control"
                                        placeholder="Password"
                                        id="pwd"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <div className="text-left">
                                    <div className="">
                                        {" "}
                                        <Link to="/forget-password-verifyemail">
                                            <span className="terms-text"> Forgot Password?</span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-cente mt-2  divide-row">
                                    <div className="w-90 d-flex align-items-center">
                                        <p>
                                            Don't have an account?
                                            <Link to="/register">
                                                <span className="terms-text"> Register</span>
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="w-10 button-grp login-btn-section">
                                        <button
                                            type="submit"
                                            className="btn btn-primary  login-btn"
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// export default Login;
Login.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.error.error,
    auth: state.auth,
});

export default connect(mapStateToProps)(Login);
