import React from "react";
import LogoOnlyHeader from "../../components/headers/LogoOnlyHeader";
import "../../styles/auth style/auth.css";
import {connect} from "react-redux";


const VerificationSent = (props) => {
    const searchParams = new URLSearchParams(window.location.search);
    let resend = searchParams.get('resend');
    const email = searchParams.get('email');

    if (resend) {
        resend = resend.toLowerCase() === "true"
    }

    return (
        <>
            <LogoOnlyHeader/>
            <div className="verify-wrapper">
                <div className="verify-container">
                    <h2 className="verify-title">
                        {
                            resend ? "Verification Sent" : "Registration Complete"
                        }
                    </h2>
                    <p className="verify-text">
                        A new verification link has been sent to <b>{email}</b>.<br></br>
                        Please close this browser tab and check your inbox, then follow the instructions to verify your account.
                    </p>
                </div>
            </div>
        </>
    )
};

export default VerificationSent;
