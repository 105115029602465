import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LogoOnlyHeader from "../../components/headers/LogoOnlyHeader";
import "../../styles/auth style/auth.css";
import {API_BASE_URL} from "../../constants/ApiBaseUrl";
import {configOption} from "../../utils/HttpHeaders";
import {toast} from "react-toastify";
import Axios from "axios";
import {connect} from "react-redux";
import PropTypes from "prop-types";


const VerifyOtp = (props) => {
    const [verifying, setVerifying] = useState(true)
    const [verified, setVerified] = useState(false)

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(window.location.search);
    const resend_to = searchParams.get('resend_to');

    useEffect(() => {
        async function loadVerified() {
            const searchParams = new URLSearchParams(window.location.search);
            const otp = searchParams.get('otp');

            try {
                const res = await Axios.post(
                    `${API_BASE_URL}/auth/verify?otp=${otp}`,
                    configOption()
                );
                if (res.status === 200) {
                    setVerified(true)
                }
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 404) {
                        setVerified(false)
                    }
                } else {
                    if (error.response) {
                        toast.error(error.response.data.message);
                    }
                }
            }

            setVerifying(false)
        }

        loadVerified();
    }, []);

    const resendOtp = async () => {
        try {
            const res = await Axios.post(
                `${API_BASE_URL}/auth/resend_verification?email=${resend_to}`,
                configOption()
            );
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const onClick = async () => {
        console.log("Click!");
        if (verified) {
            navigate("/login")
        } else {
            await resendOtp()
            navigate(`/otp-sent?resend=true&email=${resend_to}`)
        }
    }

    const verifying_text = () => {
        return (
            <>
                <h2 className="verify-title">
                    Waiting for Verification...
                </h2>
            </>
        )
    }

    const success_text = () => {
        return (
            <>
                <h2 className="verify-title">
                    Verification Successful
                </h2>
                <p className="verify-text">Welcome to Niftmint Rewards. Please proceed to login to your new account.</p>
                <button className="btn btn-primary register-btn" onClick={() => onClick()}>Login</button>
            </>
        )
    }

    const resend_text = () => {
        return (
            <>
                <h2 className="verify-title">Verification Failed</h2>
                <p className="verify-text">
                    Oh no! It looks like your verification code has expired or is invalid.<br></br>
                    Please press 'Resend' to have a new code sent to <b>{resend_to}</b>. Thank-you!
                </p>
                <button className="btn btn-primary register-btn" onClick={() => onClick()}>Resend</button>
            </>
        )
    }

    const body_text = () => {
        if (verifying) {
            return verifying_text()
        }
        if(verified) {
            return success_text()
        }
        return resend_text()
    }

    return (
        <>
            <LogoOnlyHeader/>
            <div className="verify-wrapper">
                <div className="verify-container">
                    {body_text()}
                </div>
            </div>
        </>
    )
};

VerifyOtp.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.error.error,
    auth: state.auth,
});

export default connect(mapStateToProps)(VerifyOtp);
