import React, { useEffect, useState } from "react";
import "../../styles/users style/CompleteAccount.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  API_BASE_URL,
  API_ROUTES,
} from "../../constants/ApiBaseUrl";
import { toast } from "react-toastify";
import Axios from "axios";
import HomeHeader from "../../components/headers/HomeHeader";
import { configOption } from "../../utils/HttpHeaders";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setUserLocally,
  setCurrentUser,
  setError,
  setToken,
} from "../../state/actions/auth";

const CompleteAccountForm = (props) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [xHandle, setXHandle] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [instagram, setInstagram] = useState("");

  useEffect(() => {}, []);

  const validateForm = (e) => {
    e.preventDefault();
    var { email } = JSON.parse(localStorage.niftMintUser);

    if (!firstName || !lastName ) {
      toast.error("Please fill in all the required (*) fields.");
      return false;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await Axios.patch(
        `${API_BASE_URL}${API_ROUTES.USER.COMPLETE_ACCOUNT}`,
        {
          first_name: firstName,
          last_name: lastName,
          extra_data: {
            bio: lastName,
            x_handle: xHandle,
            linkedin: linkedIn,
            instagram: instagram,
          },
        },
        configOption()
      );
      setFirstName("");
      setLastName("");
      setBio("")
      setXHandle("");
      setLinkedIn("");
      setInstagram("");

      if (res.status === 200) {
        toast.success("Details updated successfully.");
        //window.location.href = "/";
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="container d-flex justify-content-center align-items-center mt-3 mb-3 complete-container">
        <div className="row justify-content-center ">
          <div className="col-11 col-sm-10  col-md-10 col-lg-10 complete-account-section p-5 complete-account-row">
            <div>
              <p className="header title font-weight-bold" style={{display: "inline"}}>Profile</p><p style={{marginLeft: "50px", display: "inline"}}>Complete and earn 20 Niftmint Tokens</p>
            </div>

            <div className="form" style={{marginTop: "20px"}}>
              <form onSubmit={validateForm}>
                <div className="form-group">

                  <div className="form-group">
                    <label htmlFor="pwd" className="forms-label">
                      First Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        id="pwd"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pwd" className="forms-label">
                      Last Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        id="pwd"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pwd" className="forms-label">
                      Bio
                    </label>
                    <textarea
                        name=""
                        className="form-control textarea"
                        cols="50"
                        rows="4"
                        placeholder="Tell us a little about yourself!"
                        id="pwd"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pwd" className="forms-label">
                      X Handle
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="X Handle"
                        id="pwd"
                        value={xHandle}
                        onChange={(e) => setXHandle(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pwd" className="forms-label">
                      LinkedIn
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="LinkedIn"
                        id="pwd"
                        value={linkedIn}
                        onChange={(e) => setLinkedIn(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="pwd" className="forms-label">
                      Instagram
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Instagram"
                        id="pwd"
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-4 divide-row">
                  <div className=" complete-account-section">
                    <Link to="/">
                      <button
                          className="complete-account-btn  text-white"
                          style={{
                            background: "#999999",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                      >
                      Complete Later
                      </button>
                    </Link>
                  </div>
                  <div className=" complete-account-section">
                    <button
                      type="submit"
                      className="complete-account-btn text-white "
                      style={{ borderRadius: "5px", cursor: "pointer" }}
                    >
                      {" "}
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default CompleteAccountForm;

CompleteAccountForm.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(CompleteAccountForm);
