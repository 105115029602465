import React, {createContext, useState, useRef, useEffect} from "react";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Footer from "../components/footer/Footer";
import GoToTop from "../components/GoToTop";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import "../styles/common css/commonstyle.css";
import VerifyOtp from "../pages/auth/VerifyOtp";
import VerificationSent from "../pages/auth/VerificationSent";
import CompleteAccountForm from "../pages/user-details/CompleteAccountForm";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "../components/error page/ErrorPage";
import ProtectedRoutes from "./ProtectedRoutes";
import ForgetFirstScreen from "../pages/forget password/ForgetFirstScreen";
import ForgetSecondScreen from "../pages/forget password/ForgetSecondScreen";
import ForgetThirdScreen from "../pages/forget password/ForgetThirdScreen";
import Collection from "../pages/user data/collection/Collection";
import Account from "../pages/user data/account setting/Account";
import ToggleTab from "../pages/user data/Tabs/ToggleTab";
import AllNft from "../pages/home-page/Home";
import ViewNft from "../pages/user data/collection/ViewNft";
import Ordinal from "../pages/user data/ordinal/blockchain";
import TermsCondition from "../components/footer/TermsCondition";
import PrivacyPolicy from "../components/footer/PrivacyPolicy";

import "../styles/users style/userbio.css";
import "../styles/user data/userdata.css";
import Layout from "../layout/Layout";

const AppRoutes = (props) => {
    return (
        <div className="page-container">
            <div className="content-wrap">
                <ToastContainer/>
                <GoToTop/>

                <Routes>
                    <Route path="/register" element={<Register/>}></Route>
                    <Route path="/login" element={<Login/>}></Route>
                    <Route path="/verify" element={<VerifyOtp/>}></Route>
                    <Route path="/otp-sent" element={<VerificationSent/>}></Route>
                    <Route path="/terms-condition" element={<TermsCondition/>}></Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>

                    <Route path="/forget-password-verifyemail" element={<ForgetFirstScreen/>}></Route>
                    <Route path="/forget-password-verifyotp" element={<ForgetSecondScreen/>}></Route>
                    <Route path="/forget-password-createpassword" element={<ForgetThirdScreen/>}></Route>

                    <Route
                        path="/"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<AllNft/>}/>
                            </ProtectedRoutes>
                        }
                    ></Route>
                    <Route
                        path="/complete-account"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<CompleteAccountForm/>}/>
                            </ProtectedRoutes>
                        }
                    ></Route>{" "}
                    <Route
                        path="/user-info"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<ToggleTab/>}/>
                            </ProtectedRoutes>
                        }
                    ></Route>
                    <Route
                        path="/collection"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<Collection/>}/>
                            </ProtectedRoutes>
                        }
                    ></Route>
                    <Route
                        path="/ordinal"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<Ordinal/>}/>
                            </ProtectedRoutes>
                        }
                    ></Route>
                    <Route
                        path="/view-nft"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<ViewNft {...props} />}/>
                            </ProtectedRoutes>
                        }
                    ></Route>
                    <Route
                        path="/account-setting"
                        element={
                            <ProtectedRoutes>
                                <Layout children={<Account/>}/>
                            </ProtectedRoutes>
                        }
                    ></Route>
                    <Route path="*" element={<ErrorPage/>}></Route>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
};

export default AppRoutes;
