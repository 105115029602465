import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import niftmint_logo from "../../assets/niftmint_logo.png";
const Logo = () => {
  let logo;

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    logo = (
      <img
        src={niftmint_logo}
        alt=""
        className="mobile-logo"
        style={{ cursor: "pointer", objectFit: "contain", marginLeft: -10 }}
      />
    );
  }
  const isDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return <>{logo}</>;
};

export default Logo;
